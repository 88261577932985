import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface CsvTableProps {
  headers: string[];
  data: any[];
  fileName: string;
}

const CsvTable: React.FC<CsvTableProps> = ({ headers, data, fileName }) => {
  // Função auxiliar para renderizar célula baseada em condições
  const renderCell = (row: any, header: string) => {
    // Coluna Status
    if (header === "Status") {
      const status = row["_status"];
      return (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {status === "valido" ? (
            <>
              <CheckCircleIcon sx={{ color: "green", mr: 1 }} />
            </>
          ) : (
            <>
              <CancelIcon sx={{ color: "red", mr: 1 }} />
            </>
          )}
        </Box>
      );
    }

    // Coluna Nome
    if (header === "Nome") {
      return row._nome_vazio ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ color: "#FE3A8A", mr: 1 }}>
            Não informado
          </Typography>
          <Tooltip
            title="O campo Nome não foi informado no arquivo CSV. Este campo é obrigatório."
            arrow
          >
            <InfoOutlinedIcon fontSize="small" sx={{ color: "#C0135A" }} />
          </Tooltip>
        </Box>
      ) : (
        row.Nome
      );
    }

    // Coluna E-mail
    if (header === "E-mail") {
      // Verificar se o campo está vazio
      if (row._email_vazio) {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography sx={{ color: "#FE3A8A", mr: 1 }}>
              Não informado
            </Typography>
            <Tooltip
              title="O campo E-mail não foi informado no arquivo CSV."
              arrow
            >
              <InfoOutlinedIcon fontSize="small" sx={{ color: "#C0135A" }} />
            </Tooltip>
          </Box>
        );
      }

      // Verificar se o email é inválido
      if (row._email_invalido) {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{row["E-mail"]}</Typography>
            <Tooltip title="Formato de e-mail inválido" placement="top">
              <InfoOutlinedIcon
                sx={{
                  ml: 1,
                  color: "#C0135A",
                  fontSize: "1rem",
                }}
              />
            </Tooltip>
          </Box>
        );
      }

      // Email válido
      return row["E-mail"];
    }

    // Outras colunas (Perfil, etc)
    return row[header];
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ mt: 3, maxHeight: "500px", overflowY: "auto" }}
    >
      <Table stickyHeader aria-label="csv data table">
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header} sx={{ fontWeight: "bold" }}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => (
            <TableRow key={index}>
              {headers.map((header) => (
                <TableCell key={`${index}-${header}`}>
                  {renderCell(row, header)}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CsvTable;
