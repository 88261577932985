import React from "react";
import { Route, Routes } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";

import Login from "../login/pages/Login";
import Home from "../home/pages/Home";
import UsersForm from "../users/pages/UsersForm";
import UsersList from "../users/pages/UsersList";
import UsersProfile from "../users/pages/UsersProfile";
import UsersImportPreview from "../users/pages/UsersImportPreview";
import EntitiesList from "../entities/pages/EntitiesList";
import EntitiesForm from "../entities/pages/EntitiesForm";
import RolesList from "../roles/pages/RolesList";
import StudentsGroupList from "../studentsGroup/pages/StudentsGroupList";
import StudentsGroupForm from "../studentsGroup/pages/StudentsGroupForm";
import EventsList from "../events/pages/EventsList";
import EventHome from "../events/pages/EventHome";
import ScenarioForm from "../scenarios/pages/ScenarioForm";
import CheckListForm from "../checklists/pages/CheckListForm";
import CheckListList from "../checklists/pages/CheckListList";
import EventsForm from "../events/pages/EventsForm";
import EventsSchedule from "../events/pages/EventsSchedule";
import EventScenarioView from "../events/pages/EventScenarioView";
import EventsView from "../events/pages/EventsView";
import EventAppraiserFeedback from "../events/pages/EventAppraiserFeedback";
import RoomsList from "../rooms/pages/RoomsList";
import RoomsForm from "../rooms/pages/RoomsForm";
import ScenarioList from "../scenarios/pages/ScenarioList";
import ErrorPage from "../common/components/errorpage";
import { ModalComponent } from "../common/components/modal";
import { useModal } from "../common/hooks/useModal";
import EventVideoLive from "../events/pages/EventVideoLive";
import EventControlRoom from "../events/pages/EventControlRoom";
import EventDashboard from "../dashboard/pages/EventDashboard";
import MyEventsList from "../myevents/pages/MyEventsList";
import MyEventsView from "../myevents/pages/MyEventsView";
import MyEventsFeedback from "../myevents/pages/MyEventsFeedback";
import NotFound from "../NotFound";
import Guide from "../quick-guide/pages/Guide";
import EventFiles from "../events/pages/EventFiles";
import EventDataIa from "../events/pages/EventDataIa";
import ThemesList from "../themes/pages/ThemesList";
import CompotenceList from "../competences/pages/CompotenceList";
import EventsViewDataAiList from "../events/pages/EventsViewDataAiList";
import EventMeetingLive from "../events/pages/EventMeetingLive";
import SettingsWithTemplate from "../settings/pages/SettingsScreen"; // Importa o componente Settings
import StationForm from "../stations/pages/StationForm";
import GenHiList from "../genhi/pages/GenHiList";
import GenHiForm from "../genhi/pages/GenHiForm";

import {
  ADMIN,
  ALL_ROLES,
  COORDINATOR,
  STUDENT,
  TEACHER,
} from "../common/constants";

function Routers() {
  const { dataModal } = useModal();

  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/error" element={<ErrorPage />} />
        <Route
          path="/home"
          element={
            <PrivateRoute rule="all" profile={`${ALL_ROLES}`}>
              <Home />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/list"
          element={
            <PrivateRoute rule="read_user" profile={`${ADMIN},${COORDINATOR}`}>
              <UsersList />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/form/:id?"
          element={
            <PrivateRoute
              rule="create_user"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <UsersForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/profile"
          element={
            <PrivateRoute rule="all" profile={`${ALL_ROLES}`}>
              <UsersProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/import-preview"
          element={
            <PrivateRoute
              rule="create_user"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <UsersImportPreview />
            </PrivateRoute>
          }
        />
        <Route
          path="/entities/list"
          element={
            <PrivateRoute
              rule="read_entity"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <EntitiesList />
            </PrivateRoute>
          }
        />
        <Route
          path="/entities/form/:id?"
          element={
            <PrivateRoute
              rule="create_entity"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <EntitiesForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/roles/list"
          element={
            <PrivateRoute rule="read_role" profile={`${ADMIN}`}>
              <RolesList />
            </PrivateRoute>
          }
        />
        <Route
          path="/studentsgroup/list"
          element={
            <PrivateRoute
              rule="read_studentsgroup"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <StudentsGroupList />
            </PrivateRoute>
          }
        />
        <Route
          path="/studentsgroup/form/:id?"
          element={
            <PrivateRoute
              rule="create_studentsgroup"
              profile={`${ADMIN},${COORDINATOR}`}
            >
              <StudentsGroupForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/list"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/area"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/scenarios/form/:id?"
          element={
            <PrivateRoute
              rule="read_scenario"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <ScenarioForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/checklist/list"
          element={
            <PrivateRoute
              rule="read_checklist"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <CheckListList />
            </PrivateRoute>
          }
        />
        <Route
          path="/checklist/form/:id?"
          element={
            <PrivateRoute
              rule="create_checklist"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <CheckListForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/form/:eventId?"
          element={
            <PrivateRoute
              rule="create_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventsForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/schedule/:tab?"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER},${STUDENT}`}
            >
              <EventsSchedule />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/scenarios/view/:eventId/:stationId/:evaluatedId/:debriefingId"
          element={
            <PrivateRoute
              rule="read_debriefing"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventScenarioView />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/view/:eventId"
          element={
            <PrivateRoute
              rule="read_debriefing"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventsView />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/:eventId/appraiser/:appraiserId/"
          element={
            <PrivateRoute
              rule="update_debriefing"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventAppraiserFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/rooms/list"
          element={
            <PrivateRoute
              rule="read_room"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <RoomsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/rooms/form/:id?"
          element={
            <PrivateRoute
              rule="create_room"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <RoomsForm />
            </PrivateRoute>
          }
        />

        <Route
          path="/events/station/form/:event_id/:id?"
          element={
            <PrivateRoute
              rule="create_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <StationForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/scenarios/list"
          element={
            <PrivateRoute
              rule="read_scenario"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <ScenarioList />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/live/:eventId/:stationId/:evaluatedId/:debriefingId"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventVideoLive />
            </PrivateRoute>
          }
        />

        <Route
          path="/events/meeting/:eventId/:stationId/"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventMeetingLive />
            </PrivateRoute>
          }
        />

        <Route
          path="/dashboard/event/:id?"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/controlroom/:id"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventControlRoom />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/files/:id"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventFiles />
            </PrivateRoute>
          }
        />

        <Route
          path="/myevents/list"
          element={
            <PrivateRoute rule="read_event" profile={`${STUDENT}`}>
              <MyEventsList />
            </PrivateRoute>
          }
        />
        <Route
          path="/myevents/view/:id"
          element={
            <PrivateRoute rule="read_event" profile={`${STUDENT}`}>
              <MyEventsView />
            </PrivateRoute>
          }
        />
        <Route
          path="/myevents/feedback/view/:eventId/:stationId/:evaluatedId/:debriefingId"
          element={
            <PrivateRoute rule="read_event" profile={`${STUDENT}`}>
              <MyEventsFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/myevents/feedback/view/:eventId/:stationId/:evaluatedId/:debriefingId"
          element={
            <PrivateRoute rule="read_event" profile={`${STUDENT}`}>
              <MyEventsFeedback />
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute rule="all" profile={`${ALL_ROLES}`}>
              <NotFound />
            </PrivateRoute>
          }
        />
        <Route
          path="/home/quickguide"
          element={
            <PrivateRoute rule="read_entity" profile={`${ADMIN}`}>
              <Guide />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/data-ai/:eventId"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventsViewDataAiList />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/data-ai/detail/:eventId/:userId/:debriefingId"
          element={
            <PrivateRoute
              rule="read_event"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <EventDataIa />
            </PrivateRoute>
          }
        />

        <Route
          path="/themes/list"
          element={
            <PrivateRoute
              rule="read_theme"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <ThemesList />
            </PrivateRoute>
          }
        />

        <Route
          path="/competences/list"
          element={
            <PrivateRoute
              rule="read_competence"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <CompotenceList />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute
              rule="read_room,read_studentsgroup,read_entity,read_user,read_role,read_theme,read_competence"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <SettingsWithTemplate />
            </PrivateRoute>
          }
        />
        <Route
          path="/genhi/list"
          element={
            <PrivateRoute
              rule="read_scenario"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <GenHiList />
            </PrivateRoute>
          }
        />
        <Route
          path="/genhi/form/:id?"
          element={
            <PrivateRoute
              rule="read_scenario"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <GenHiForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/users/csv-preview"
          element={
            <PrivateRoute
              rule="read_user"
              profile={`${ADMIN},${COORDINATOR},${TEACHER}`}
            >
              <UsersImportPreview />
            </PrivateRoute>
          }
        />
      </Routes>

      {dataModal.visible && <ModalComponent />}
    </>
  );
}

export default Routers;
