import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDefaultStyles } from "../../common/styles/default";
import colors from "../../common/styles/colors";
import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/banner_title_page_list_users.png";
import CsvTable from "./csv/CsvTable";
import CsvActions from "./csv/CsvActions";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import TitleForm from "../../common/components/titleform";
import Papa from "papaparse";
import { useStyles } from "../styles/index.styles";

import UsersService from "../../services/UsersService";

export default function CsvPreview() {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useStyles();
  const [csvData, setCsvData] = useState<any[]>([]);
  const [headers, setHeaders] = useState<string[]>([]);
  const [fileName, setFileName] = useState<string>("");
  const [fileLoaded, setFileLoaded] = useState<boolean>(false);

  // Estado para o modal de confirmação
  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false);

  // Adicionar novo estado para armazenar os resultados da API
  const [resultados, setResultados] = useState<
    { name: string; email: string; success: boolean }[]
  >([]);
  const [cadastroConcluido, setCadastroConcluido] = useState<boolean>(false);

  // Função para exibir toast usando Toastify
  const showToast = (
    message: string,
    severity: "success" | "error" | "info" | "warning" = "info"
  ) => {
    switch (severity) {
      case "success":
        toast.success(message);
        break;
      case "error":
        toast.error(message);
        break;
      case "warning":
        toast.warning(message);
        break;
      case "info":
      default:
        toast.info(message);
        break;
    }
  };

  // Referência para o input de arquivo oculto
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Recuperar dados do CSV do localStorage
    const storedData = secureLocalStorage.getItem("csvData");
    const storedFileName = secureLocalStorage.getItem("csvFileName");
    const storedHeaders = secureLocalStorage.getItem("csvHeaders");

    if (storedData) {
      try {
        const parsedData = JSON.parse(storedData as string);
        setCsvData(parsedData);
        setFileLoaded(true);

        // Usar os cabeçalhos armazenados ou definir padrão
        if (storedHeaders) {
          setHeaders(JSON.parse(storedHeaders as string));
        } else if (parsedData.length > 0) {
          // Cabeçalhos padrão para exibição se não houver explícitos
          setHeaders(["Nome", "E-mail", "Perfil", "Status"]);
        }

        if (storedFileName) {
          setFileName(storedFileName as string);
        }
      } catch (error) {
        console.error("Erro ao analisar dados armazenados:", error);
        // Limpar dados inválidos
        secureLocalStorage.removeItem("csvData");
        secureLocalStorage.removeItem("csvFileName");
        secureLocalStorage.removeItem("csvHeaders");
      }
    }
  }, []);

  const handleDownloadCsv = () => {
    if (csvData.length === 0) return;

    // Criar o conteúdo do CSV
    const csvContent = [
      headers.join(","),
      ...csvData.map((row) => headers.map((header) => row[header]).join(",")),
    ].join("\n");

    // Criar um blob e link para download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName || "exported_data.csv");
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleDeleteFile = () => {
    // Limpar os dados do CSV
    setCsvData([]);
    setHeaders([]);
    setFileName("");
    setFileLoaded(false);

    // Remover dados do localStorage
    secureLocalStorage.removeItem("csvData");
    secureLocalStorage.removeItem("csvFileName");
    secureLocalStorage.removeItem("csvHeaders");
  };

  const handleFileSelect = () => {
    // Aciona o clique no input de arquivo oculto
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    // Verificar se o arquivo é CSV
    if (!file.name.toLowerCase().endsWith(".csv")) {
      alert("Por favor, selecione um arquivo CSV válido.");
      return;
    }

    // Usar Papa Parse para processar o CSV
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      delimiter: ";",
      transformHeader: (header) => {
        if (header.toLowerCase() === "nome") return "Nome";
        if (header.toLowerCase() === "email") return "E-mail";
        return header;
      },
      complete: (results) => {
        let data = results.data as Record<string, string>[];

        if (data.length === 0) {
          alert("O arquivo CSV está vazio ou não contém dados válidos.");
          return;
        }

        // Função para validar e-mail usando regex
        const isValidEmail = (email: string): boolean => {
          const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          return emailRegex.test(email);
        };

        // Processar os dados
        const processedData = data.map((row) => {
          // Copiar todos os dados originais
          const processedRow: Record<string, any> = { ...row };

          // Garantir que o campo Nome exista
          if (!("Nome" in processedRow)) {
            processedRow["Nome"] = "";
          }

          // Garantir que o campo E-mail exista
          if (!("E-mail" in processedRow)) {
            processedRow["E-mail"] = "";
          }

          // Adicionar campos de validação
          const nome = String(processedRow["Nome"] || "").trim();
          const email = String(processedRow["E-mail"] || "").trim();

          processedRow["_nome_vazio"] = !nome;
          processedRow["_email_vazio"] = !email;

          // Validar formato do e-mail se estiver preenchido
          const emailInvalido = email !== "" && !isValidEmail(email);
          processedRow["_email_invalido"] = emailInvalido;

          // Formatar o e-mail com o ícone se for inválido
          if (emailInvalido) {
            processedRow["_email_formatado"] = {
              valor: email,
              invalido: true,
              mensagem: "Formato de e-mail inválido",
            };
          } else {
            processedRow["_email_formatado"] = {
              valor: email,
              invalido: false,
            };
          }

          // Adicionar Perfil Discente
          processedRow["Perfil"] = "Discente";

          // Status geral - válido apenas se nome preenchido E email preenchido e válido
          const linhaValida = nome && email && isValidEmail(email);
          processedRow["_status"] = linhaValida ? "valido" : "invalido";

          return processedRow;
        });

        // Cabeçalhos para exibição
        const visibleHeaders = ["Nome", "E-mail", "Perfil", "Status"];

        secureLocalStorage.setItem("csvData", JSON.stringify(processedData));
        secureLocalStorage.setItem("csvFileName", file.name);
        secureLocalStorage.setItem(
          "csvHeaders",
          JSON.stringify(visibleHeaders)
        );

        setCsvData(processedData);
        setHeaders(visibleHeaders);
        setFileName(file.name);
        setFileLoaded(true);

        // Resetar o estado de cadastro concluído e resultados
        setCadastroConcluido(false);
        setResultados([]);
      },
      error: (error) => {
        console.error("Erro ao processar o arquivo CSV:", error);
        alert("Erro ao processar o arquivo. Verifique se é um CSV válido.");
      },
    });

    event.target.value = "";
  };

  const handleSendToApi = async () => {
    if (csvData.length === 0) return;

    try {
      // Recuperar entity_id do localStorage
      const entityId = secureLocalStorage.getItem("entity");

      if (!entityId) {
        showToast(
          "ID da entidade não encontrado. Impossível continuar.",
          "error"
        );
        return;
      }

      // Filtrar apenas linhas válidas e processar dados
      const validRows = csvData
        .filter((row) => row._status === "valido")
        .map((row) => {
          // Criar novo objeto sem a coluna Perfil e dados internos de validação
          const {
            Perfil,
            _status,
            _nome_vazio,
            _email_vazio,
            _email_invalido,
            _email_formatado,
            ...rest
          } = row;

          // Adicionar entity_id
          return {
            ...rest,
            entity_id: entityId,
          };
        });

      if (validRows.length === 0) {
        showToast("Não há linhas válidas para enviar.", "warning");
        return;
      }

      // Mostrar modal de confirmação
      setConfirmDialogOpen(true);
    } catch (error) {
      console.error("Erro ao processar dados:", error);
      showToast(
        "Ocorreu um erro ao processar os dados. Por favor, tente novamente.",
        "error"
      );
    }
  };

  // Nova função para executar o envio após a confirmação
  const confirmSendToApi = async () => {
    try {
      // Fechar o modal
      setConfirmDialogOpen(false);

      // Recuperar entity_id do localStorage
      const entityId = secureLocalStorage.getItem("entity");

      // Filtrar apenas linhas válidas e processar dados
      const validRows = csvData
        .filter((row) => row._status === "valido")
        .map((row) => {
          // Criar novo objeto sem a coluna Perfil e dados internos de validação
          const {
            Perfil,
            _status,
            _nome_vazio,
            _email_vazio,
            _email_invalido,
            _email_formatado,
            ...rest
          } = row;

          // Adicionar entity_id
          return {
            ...rest,
            entity_id: entityId,
          };
        });

      // Obter os cabeçalhos sem "Perfil" e com "entity_id"
      const csvHeaders = headers
        .filter((h) => h !== "Perfil")
        .concat(["entity_id"]);

      // Criar conteúdo do CSV
      const csvContent = [
        csvHeaders.join(","),
        ...validRows.map((row) =>
          csvHeaders
            .map((header) =>
              header === "Status" ? "" : String(row[header] || "")
            )
            .join(",")
        ),
      ].join("\n");

      // Criar um blob com o conteúdo do CSV
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Criar um arquivo a partir do blob
      const csvFile = new File([blob], "usuarios_validos.csv", {
        type: "text/csv",
      });

      // Criar o FormData e anexar o arquivo
      const formData = new FormData();
      formData.append("file", csvFile);

      // Enviar para a API
      const response = await UsersService.importUser(formData);

      if (response.status === 200 || response.status === 201) {
        // Atualizar com os resultados da API
        if (response.data && response.data.results) {
          setResultados(response.data.results);

          // Adicionar a coluna "Criado" aos cabeçalhos
          const novosHeaders = [...headers, "Criado"];
          setHeaders(novosHeaders);

          // Atualizar csvData com os resultados da API
          const dadosAtualizados = csvData.map((row) => {
            const emailRow = row["E-mail"];
            const resultadoItem = response.data.results.find(
              (item: { email: string }) => item.email === emailRow
            );

            if (resultadoItem) {
              return {
                ...row,
                Status: resultadoItem.success
                  ? "Importado com sucesso"
                  : "Falha na importação",
                Criado: resultadoItem.success ? "Sim" : "Não",
              };
            }
            return {
              ...row,
              Criado: "Não processado",
            };
          });

          setCsvData(dadosAtualizados);
          setCadastroConcluido(true);
        }

        showToast("Usuários importados com sucesso!", "success");
      }
    } catch (error) {
      console.error("Erro ao enviar dados para API:", error);
      showToast(
        "Ocorreu um erro ao importar os usuários. Por favor, tente novamente.",
        "error"
      );
    }
  };

  // Função para cancelar o envio
  const cancelSendToApi = () => {
    setConfirmDialogOpen(false);
  };

  // Função para voltar para a página de lista de usuários
  const handleBack = () => {
    navigate("/users/list");
  };

  // Função para verificar se todos os status estão errados
  const verificarTodosStatusErrados = () => {
    // Implemente sua lógica específica aqui
    return csvData.every(
      (row) => row._status === "invalido" || row._status === false
    );
  };

  // Função para verificar se o arquivo está vazio
  const arquivoEstaVazio = () => {
    // Implemente sua lógica específica aqui
    // Por exemplo:
    return csvData.length === 0;
  };

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <GroupsOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              Cadastro em lote
            </Typography>
          </Box>
        }
      />
      <Box>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            paddingLeft: "140px",
            paddingRight: "220px",
            paddingTop: "60px",
            paddingBottom: "60px",

            [theme.breakpoints.down("sm")]: {
              position: "relative",
              ml: "25px",
              width: "94%",
              paddingLeft: "5px",
              paddingRight: "30px",
            },

            [theme.breakpoints.between("sm", "md")]: {
              position: "relative",
              ml: "25px",
              width: "94%",
              paddingLeft: "53px",
              paddingRight: "53px",
            },

            [theme.breakpoints.between("md", "lg")]: {
              position: "relative",
              ml: "25px",
              width: "92%",
              paddingLeft: "34px",
              paddingRight: "30px",
            },

            [theme.breakpoints.between("lg", "xl")]: {
              position: "relative",
              ml: "25px",
              width: "96%",
              paddingLeft: "65px",
              paddingRight: "75px",
            },
          }}
        >
          <TitleForm title="Arquivo selecionado" />
          <Typography>
            Veja o arquivo selecionado. Relembrando que o arquivo deve estar no
            formato .csv e deve conter os campos obrigatórios necessários para o
            cadastro, sendo eles: Nome, email, perfil desejado. Demais campos
            como número de matricula pode ser preenchido posteriormente pelo
            usuário.
          </Typography>

          {/* Input de arquivo oculto */}
          <input
            type="file"
            ref={fileInputRef}
            accept=".csv"
            style={{ display: "none" }}
            onChange={handleFileUpload}
          />

          <CsvActions
            onDownload={handleDownloadCsv}
            onDelete={handleDeleteFile}
            onAddFile={handleFileSelect}
            onSendToApi={handleSendToApi}
            fileName={fileName}
            fileLoaded={fileLoaded}
          />

          {fileLoaded && <TitleForm title="Usuários carregados" />}
          {fileLoaded && (
            <Typography>
              Abaixo é possível visualizar os usuários carregados pelo arquivo
              selecionado. A coluna Status indica se existe alguma
              irregularidade nas informações do usuário.{" "}
              <b>
                Caso existam irregularidades altere o arquivo original e
                recarregue-o.
              </b>
            </Typography>
          )}

          {fileLoaded && csvData.length > 0 ? (
            <CsvTable headers={headers} data={csvData} fileName={fileName} />
          ) : (
            <Box sx={{ my: 4, textAlign: "center" }}>
              <Typography variant="body1">
                Nenhum arquivo carregado. Por favor, adicione um arquivo CSV.
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Modal de confirmação */}
      <Dialog
        open={confirmDialogOpen}
        onClose={cancelSendToApi}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          Confirmar importação
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            Tem certeza que deseja criar o cadastro?
          </DialogContentText>
        </DialogContent>

        <Divider sx={{ my: 2 }} />

        <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
          <Button
            onClick={cancelSendToApi}
            color="secondary"
            variant="contained"
          >
            Não
          </Button>
          <Button
            onClick={confirmSendToApi}
            color="primary"
            variant="contained"
            autoFocus
            sx={{ mx: 1 }}
          >
            Sim
          </Button>
        </DialogActions>
      </Dialog>

      {!arquivoEstaVazio() && (
        <Box
          className={styles.container}
          sx={{
            [theme.breakpoints.down("sm")]: {
              width: "100%",
            },
          }}
        >
          <Typography>
            {cadastroConcluido
              ? "Cadastro concluído! Na tabela se encontra o resultado da importação. Alguns cadastros podem ter falhado, verifique a coluna criado para mais detalhes."
              : "Após revisar as informações, confirme a criação do cadastro clicando em CRIAR CADASTRO."}
          </Typography>

          <Box sx={{ width: "100%", mt: 2 }}>
            {!verificarTodosStatusErrados() && !cadastroConcluido && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendToApi}
              >
                Criar cadastro
              </Button>
            )}
            <Button
              variant="contained"
              sx={{ ml: 2 }}
              color="secondary"
              onClick={handleBack}
            >
              Voltar
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}
