import {
  Box,
  Button,
  Grid,
  Typography,
  IconButton,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState, useEffect } from "react";
import { useStyles } from "../styles/index.styles";
import { useNavigate } from "react-router-dom";
import AvatarHiplade from "../../common/components/avatarhiplade";
import StationsService from "../../services/StationsService";
import StreamingService from "../../services/StreamingService";
import colors from "../../common/styles/colors";
import loadstatus from "../../assets/images/load-status.gif";
import AttachmentService from "../../services/AttachmentService";
import DebriefingService from "../../services/DebriefingService";
import { toast } from "react-toastify";
import LoaderHiplade from "../../common/components/loaderhiplade";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import UploadIcon from "@mui/icons-material/Upload";
import LiveTvIcon from "@mui/icons-material/LiveTv";
import StopIcon from "@mui/icons-material/Stop";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import EditIcon from "@mui/icons-material/Edit";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import ArrowForwardOutlinedIcon from "@mui/icons-material/ArrowForwardOutlined";

export default function StationStudentsList({
  studentsgroupId,
  eventId,
  stationId,
  status,
  station,
}: any) {
  if (!studentsgroupId) {
    return <Box />;
  }

  const { t } = useTranslation();

  const arrStatus = ["not_corrected", "correcting", "corrected", "revised"];
  const arrErrorStatus = [
    "error_create_streaming_hiplade",
    "error_merdiaservice_create_streaming",
    "error_configuring_obs",
    "rtmp_connection_failed",
    "error_hiplade_transmition_method",
    "error_hiplade_useffmpeg",
    "error_agent",
    "error_rca",
  ];

  const [students, setStudents] = useState([] as any);
  const [inSimulation, setInSimulation] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("sm"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isBelow1200 = useMediaQuery(theme.breakpoints.down("lg"));

  const styles = useStyles();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const getStudentsList = async (stationId: any) => {
    await StationsService.getUsersStations(stationId)
      .then((response: any) => {
        if (response.data && response.data.evaluateds.length > 0) {
          setStudents(response.data.evaluateds);
          const status = response.data.in_simulation === "true" ? true : false;
          setInSimulation(status);
        }
      })
      .catch((e: Error) => {});
  };

  const startDebriefing = async (
    studentId: any,
    eventId: any,
    stationId: any,
    debriefingId: any
  ) => {
    navigate(
      `/events/scenarios/view/${eventId}/${stationId}/${studentId}/${debriefingId}`
    );
  };

  const colorStatus = (status: any) => {
    if (status == "initializing" || status == "processing_recording") {
      return colors.purpleStatus;
    } else if (status == "not_corrected" || status == "in_simulation") {
      return colors.pinkLight;
    } else if (status == "correcting") {
      return colors.yellow;
    } else if (arrErrorStatus?.includes(status)) {
      return colors.pink;
    }
    {
      return colors.greenMedium;
    }
  };

  const startSimulation = async (evaluated_id: any) => {
    const data = {
      evaluated_id: evaluated_id,
      station_id: stationId,
      event_id: eventId,
      name: `Streaming ${evaluated_id}${stationId}${eventId}`,
    };
    await StreamingService.store(data)
      .then((response: any) => {
        if (response.data) {
          getStudentsList(stationId);
        }
      })
      .catch((e: Error) => {});
  };

  const stopSimulation = async (streaming_id: any) => {
    await StreamingService.stop(streaming_id)
      .then((response: any) => {
        if (response.data) {
          getStudentsList(stationId);
        }
      })
      .catch((e: Error) => {});
  };

  const storeDebriefing = async (
    appraiser_id: any,
    evaluated_id: any,
    e: any
  ) => {
    if (e.target.files[0].type != "video/mp4") {
      toast(t("toast_type_video"), {
        type: "error",
      });
      return;
    }

    setLoader(true);
    const data = {
      evaluated_id: evaluated_id,
      station_id: stationId,
      event_id: eventId,
      appraiser_id: appraiser_id,
      status: "not_corrected",
    };
    await DebriefingService.store(data)
      .then((response: any) => {
        if (response.data) {
          const debriefing_id = response.data.debriefing_id;
          uploadFile(e, debriefing_id);
        }
      })
      .catch((e: Error) => {
        setLoader(false);
      });
  };

  const uploadFile = async (e: any, debriefing_id: any) => {
    const data = {
      feature: "debriefing",
      object_id: debriefing_id,
      field_name: "debriefing_video",
      file: e.target.files[0],
      file_type: "video",
      multiple: "false",
    };

    await AttachmentService.upload(data)
      .then((response) => {
        setLoader(false);
        getStudentsList(stationId);
        toast(t("toast_load_video"), {
          type: "success",
        });
      })
      .catch((e: any) => {
        setLoader(false);
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  // Função para abrir o modal
  const handleOpenModal = (student: any) => {
    setSelectedStudent(student);
    setOpenModal(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedStudent(null);
  };

  useEffect(() => {
    if (stationId) {
      getStudentsList(stationId);
    }
  }, [studentsgroupId, eventId, stationId]);

  return (
    <Box>
      {loader && <LoaderHiplade />}
      {students
        ? students.map((student, index) => (
            <Grid
              container
              key={index}
              spacing={1}
              className={styles.eventStationListStudents}
            >
              {isLargeScreen && (
                <Grid item xs={2} sm={2} md={2}>
                  <Typography className={styles.textDetail}>
                    + Detalhes
                  </Typography>
                </Grid>
              )}

              <Grid
                item
                xs={isLargeScreen ? 2 : 4}
                sm={isLargeScreen ? 6 : 8}
                md={isLargeScreen ? 6 : 8}
                className={styles.boxContentAvatar}
                onClick={
                  isSmallScreen && !student?.debriefing_status
                    ? () => handleOpenModal(student)
                    : undefined
                }
                sx={
                  isSmallScreen && !student?.debriefing_status
                    ? { cursor: "pointer" }
                    : {}
                }
              >
                <AvatarHiplade
                  nameUser={student?.user_name}
                  size="mini"
                  theme="gray"
                  id={student?.user_id}
                />
                <Typography className={styles.titleStudent}>
                  {isMediumScreen && (
                    <span style={{ fontWeight: "bold" }}>Aluno (a)/ </span>
                  )}
                  {student?.user_name}
                </Typography>
              </Grid>

              <Grid
                item
                xs={isLargeScreen ? 4 : 8}
                sm={isLargeScreen ? 4 : 4}
                md={isLargeScreen ? 4 : 4}
                className={styles.alignButtonsListStation}
              >
                {status !== "concluded" &&
                  !student?.debriefing_status &&
                  !isSmallScreen && (
                    <Box>
                      <input
                        id={"arq" + index}
                        name={"arq" + index}
                        style={{ display: "none" }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          if (event.target.files != null) {
                            storeDebriefing(
                              student?.appraisers?.id,
                              student?.user_id,
                              event
                            );
                          }
                        }}
                        accept="application/mp4"
                        type="file"
                      />
                      <label htmlFor={"arq" + index}>
                        {isSmallScreen ? null : ( // Ícone não será exibido em telas pequenas quando não há status
                          // Botão para telas maiores (código original)
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              borderRadius: "4px",
                              cursor: "pointer",
                              color: colors.pinkLight,
                              border: "2px solid " + colors.pinkLight,
                              textTransform: "uppercase",
                              paddingLeft: isMediumScreen ? "10px" : "5px",
                              paddingRight: isMediumScreen ? "10px" : "5px",
                              paddingTop: "8px",
                              paddingBottom: "8px",
                              textAlign: "center",
                              width: isMediumScreen
                                ? "170px !important"
                                : "auto",
                              minWidth: !isMediumScreen ? "120px" : "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: isBelow1200 ? "11px" : "inherit",
                            }}
                          >
                            {isMediumScreen
                              ? t("btn_upload_video")
                              : t("btn_upload")}
                          </Typography>
                        )}
                      </label>
                    </Box>
                  )}

                {student?.debriefing_status && !isSmallScreen && (
                  <Typography
                    sx={{
                      fontWeight: 700,
                      color: `${colorStatus(student?.debriefing_status)}`,
                      fontSize: isBelow1200 ? "12px" : "inherit",
                    }}
                  >
                    {t(student?.debriefing_status)}
                  </Typography>
                )}
                {(student?.debriefing_status == "initializing" ||
                  student?.debriefing_status == "processing_recording") &&
                  (isSmallScreen ? (
                    <img src={loadstatus} style={{ marginLeft: 15 }} />
                  ) : (
                    <img src={loadstatus} style={{ marginLeft: 15 }} />
                  ))}
                {status !== "concluded" &&
                  student?.debriefing_status == "in_simulation" &&
                  (isSmallScreen ? (
                    <IconButton
                      sx={{
                        ml: 0.5,
                        color: colorStatus(student?.debriefing_status),
                      }}
                      onClick={() => {
                        navigate(
                          `/events/live/${eventId}/${stationId}/${student?.user_id}/${student?.debriefing_id}`
                        );
                      }}
                      aria-label={String(t("bt_live"))}
                    >
                      <LiveTvIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="outlined"
                      color="error"
                      sx={{
                        ml: isMediumScreen ? 2 : 0.5,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                        padding: isBelow1200 ? "6px 8px" : "6px 16px",
                      }}
                      onClick={() => {
                        navigate(
                          `/events/live/${eventId}/${stationId}/${student?.user_id}/${student?.debriefing_id}`
                        );
                      }}
                    >
                      {t("bt_live")}
                    </Button>
                  ))}
                {student?.debriefing_status == "in_simulation" &&
                  (isSmallScreen ? (
                    <IconButton
                      sx={{
                        ml: 0.5,
                        color: colorStatus(student?.debriefing_status),
                      }}
                      onClick={() => stopSimulation(student?.streaming_id)}
                      aria-label={String(t("bt_finish"))}
                    >
                      <StopIcon />
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      size={isMediumScreen ? "large" : "medium"}
                      color="secondary"
                      sx={{
                        ml: isMediumScreen ? 2 : 0.5,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                      }}
                      onClick={() => stopSimulation(student?.streaming_id)}
                    >
                      {t("bt_finish")}
                    </Button>
                  ))}

                {arrStatus?.includes(student?.debriefing_status) &&
                  (isSmallScreen ? (
                    <IconButton
                      sx={{
                        ml: 0.5,
                        color: colorStatus(student?.debriefing_status),
                      }}
                      onClick={() =>
                        startDebriefing(
                          student.user_id,
                          eventId,
                          stationId,
                          student?.debriefing_id
                        )
                      }
                      aria-label={String(
                        student?.debriefing_status == "corrected" ||
                          student?.debriefing_status == "revised"
                          ? t("btn_access")
                          : student?.debriefing_status == "correcting"
                          ? t("btn_in_progress")
                          : t("bt_correct")
                      )}
                    >
                      {student?.debriefing_status == "corrected" ||
                      student?.debriefing_status == "revised" ? (
                        <CheckCircleIcon />
                      ) : student?.debriefing_status == "correcting" ? (
                        <AccessTimeFilledIcon />
                      ) : (
                        <ErrorOutlinedIcon />
                      )}
                    </IconButton>
                  ) : (
                    <Button
                      variant="contained"
                      size={isMediumScreen ? "large" : "medium"}
                      color="secondary"
                      sx={{
                        ml: isMediumScreen ? 2 : 0.5,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                      }}
                      onClick={() =>
                        startDebriefing(
                          student.user_id,
                          eventId,
                          stationId,
                          student?.debriefing_id
                        )
                      }
                    >
                      {" "}
                      {student?.debriefing_status == "corrected" ||
                      student?.debriefing_status == "revised"
                        ? isMediumScreen
                          ? t("btn_access")
                          : t("btn_access")
                        : student?.debriefing_status == "correcting"
                        ? isMediumScreen
                          ? t("in_progress")
                          : t("in_progress")
                        : isMediumScreen
                        ? t("bt_correct")
                        : t("bt_correct")}
                    </Button>
                  ))}

                {station.has_camera &&
                  status !== "concluded" &&
                  (arrErrorStatus?.includes(student?.debriefing_status) ||
                    !student?.debriefing_status) &&
                  !inSimulation &&
                  !isSmallScreen && (
                    <Button
                      variant="outlined"
                      size={isMediumScreen ? "large" : "medium"}
                      sx={{
                        ml: isMediumScreen ? 2 : 0.5,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                      }}
                      className={styles.buttonStartSimulation}
                      onClick={() => startSimulation(student?.user_id)}
                    >
                      {isMediumScreen
                        ? t("btn_start_simulation")
                        : t("btn_start")}
                    </Button>
                  )}

                {!inSimulation &&
                  isSmallScreen &&
                  !student?.debriefing_status && (
                    <IconButton
                      sx={{
                        color: colors.purple,
                      }}
                      onClick={() => handleOpenModal(student)}
                      aria-label={String(t("btn_options"))}
                    >
                      <ArrowForwardOutlinedIcon />
                    </IconButton>
                  )}
              </Grid>
            </Grid>
          ))
        : "Carregando..."}

      {/* Modal que será exibido ao clicar no nome do aluno em telas pequenas */}
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="xs"
      >
        {selectedStudent && (
          <>
            <DialogContent>
              <Typography
                variant="body1"
                sx={{ mb: 2, fontSize: isBelow1200 ? "14px" : "16px" }}
              >
                Para iniciar a estação escolha uma das opções abaixo:
              </Typography>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                {station.has_camera && !inSimulation && (
                  <Grid item xs={6}>
                    <Button
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: colors.greenMedium,
                        borderColor: colors.greenMedium,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                      }}
                      onClick={() => {
                        startSimulation(selectedStudent?.user_id);
                        handleCloseModal();
                      }}
                    >
                      {t("btn_start_simulation")}
                    </Button>
                  </Grid>
                )}
                <Grid item xs={station.has_camera && !inSimulation ? 6 : 12}>
                  <input
                    id="modal-upload"
                    name="modal-upload"
                    style={{ display: "none" }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      if (event.target.files != null) {
                        storeDebriefing(
                          selectedStudent?.appraisers?.id,
                          selectedStudent?.user_id,
                          event
                        );
                        handleCloseModal();
                      }
                    }}
                    accept="application/mp4"
                    type="file"
                  />
                  <label htmlFor="modal-upload" style={{ width: "100%" }}>
                    <Button
                      component="span"
                      variant="outlined"
                      fullWidth
                      sx={{
                        color: colors.pinkLight,
                        borderColor: colors.pinkLight,
                        fontSize: isBelow1200 ? "12px" : "inherit",
                      }}
                    >
                      {t("btn_upload_video")}
                    </Button>
                  </label>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions></DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}
