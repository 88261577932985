import React from "react";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDefaultStyles } from "../../common/styles/default";
import { useStyles } from "../styles/index.styles";
import Banner from "../../common/components/banner";
import event_banner from "../../assets/images/banners/banner_prepare_events.png";
import EventBoxCreate from "./EventBoxCreate";
import EventBoxCreateLinks from "./EventBoxCreateLinks";
import { Button, Grid, Modal, Stack, Typography } from "@mui/material";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import colors from "../../common/styles/colors";
import CollectionsRoundedIcon from "@mui/icons-material/CollectionsRounded";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import Scenario from "../../assets/images/icons/cenario.png";
import Plus from "../../assets/images/icons/plus.png";
import Schedule from "../../assets/images/icons/agenda.png";
import Equal from "../../assets/images/icons/equal.png";
import Checklist from "../../assets/images/icons/checklist.png";
import Check from "../../assets/images/icons/check.png";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";

export default function EventArea() {
  const { t } = useTranslation();

  const stytesEvent = useStyles();
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goGenHi = () => {
    navigate("/genhi/list");
  };

  return (
    <Box>
      <Banner
        type="big"
        image={event_banner}
        title={t("title_page_events")}
        subtitle={t("banner_subtitle_settings")}
        divider="1"
        colorDivider="new"
      />
      <Box
        className={stytesEvent.boxArea}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          mt: 5,
        }}
      >
        <Stack gap={3} onClick={handleOpen}>
          <Typography mt={5}>
            {t("first_title_description_event_area")}
            <strong>{t("first_strong_description_event_area")}</strong>
            {t("second_title_description_event_area")}
            <strong>{t("second_strong_description_event_area")}</strong>
            {t("third_title_description_event_area")}
            <strong>{t("third_strong_description_event_area")}</strong>
            {t("fourth_title_description_event_area")}
          </Typography>
          <Stack
            flexDirection={"row"}
            gap={1}
            alignItems={"center"}
            sx={{ cursor: "pointer" }}
          >
            <strong>{t("fourth_strong_description_event_area")}</strong>
            <ErrorOutlinedIcon sx={{ fontSize: 16 }} />
          </Stack>
        </Stack>
        <EventBoxCreate />
        <Box
          sx={{
            padding: 3,
            backgroundColor: colors.purpleLightStation,
            borderRadius: 2,
          }}
        >
          <Grid container>
            <Grid item sm={1}>
              <AutoAwesomeOutlinedIcon
                sx={{ fontSize: 30, color: colors.purple }}
              />
            </Grid>
            <Grid item sm={8}>
              <b>GenHI:</b> Crie o conjunto (Cenário + Checklist) rapidamente
              através da IA.
            </Grid>
            <Grid item sm={3} sx={{ textAlign: "right" }}>
              <Button variant="contained" color="primary" onClick={goGenHi}>
                Backgrounds IA
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box bgcolor={colors.purpleLightStation} sx={{ py: 8, px: 15, mt: 10 }}>
        <Typography sx={{ mb: 5 }}>
          {t("description_dialog_cards_eventArea")}
        </Typography>
        <EventBoxCreateLinks />
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Stack
            bgcolor={colors.purpleLightStation}
            sx={{ alignItems: "flex-end" }}
          >
            <Button
              variant="text"
              onClick={handleClose}
              sx={{ px: 1 }}
              endIcon={<CloseOutlinedIcon />}
            >
              Fechar
            </Button>
            <Stack
              sx={{ flexDirection: "row", p: 5, gap: 5, alignItems: "center" }}
            >
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={110}
              >
                <img src={Scenario} />
                <strong>{t("title_icon_scenario")}</strong>
                <Typography fontSize={"14px"} textAlign={"center"}>
                  {t("description_icon_scenario")}
                </Typography>
              </Stack>
              <img src={Plus} />
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={110}
              >
                <img src={Checklist} />
                <strong>{t("title_icon_checklist")}</strong>
                <Typography fontSize={"14px"} textAlign={"center"}>
                  {t("description_icon_checklist")}
                </Typography>
              </Stack>
              <img src={Plus} />
              <Stack
                justifyContent={"center"}
                alignItems={"center"}
                width={110}
              >
                <img src={Schedule} />
                <strong>{t("title_icon_schedule")}</strong>
                <Typography fontSize={"14px"} textAlign={"center"}>
                  {t("description_icon_schedule")}
                </Typography>
              </Stack>
              <img src={Equal} />
              <Stack
                justifyContent={"center"}
                textAlign={"center"}
                alignItems={"center"}
                width={110}
              >
                <img src={Check} />
                <strong>{t("title_icon_check")}</strong>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Box>
  );
}
