const black = "#000000 !important";
const blackMedium = "#212125 !important";
const blackLight = "#3D3B43 !important";
const blackDark = "#393643 !important";
const blue = "#1432D9 !important";
const blueDark = "#200A66 !important";
const blueDarkest = "#38324C !important";
const blueMedium = "#1026A8 !important";
const gray = "#BDBDBD !important";
const grayDLight = "#E1E1E1 !important";
const grayLight = "#F6F5FB !important";
const grayFeedbackBg = "#F1ECEC !important";
const grayLightest = "#F6F5F8 !important";
const grayMedium = "#868687 !important";
const grayBarPerformance = "#F7F4F4 !important";
const grayTitlePoints = "#6E6E6E !important";
const grayMediumLight = "#D9D9D9 !important";
const grayStatusDataIA = "#E4E4E4 !important";
const green = "#9DB938 !important";
const greenMedium = "#42B938 !important";
const greenDark = "#348769 !important";
const greenMediumLight = "#6C9C1E !important";
const greenDarkButton = "#068657 !important";
const lilac = "#CCC7DD !important";
const lilacMedium = "#F3EDFC !important";
const lilacMediumLight = "#A695DE !important";
const lilacDarkMedium = "#C2B1FB !important";
const lilacLight = "#E3DCFA !important";
const lilacLightest = "#C1B8CF !important";
const lilacPerformance = "#f1edfc !important";
const lilacPerformanceTitle = "#d5c8ed !important";
const pink = "#CB0152 !important";
const pinkLight = "#FE3A8A !important";
const pinkDark = "#CB0152 !important";
const purple = "#601DCC !important";
const purpleDark = "#2B2440 !important";
const purpleDarkest = "#2A0D59 !important";
const purpleButtonCam = "#150042 !important";
const purpleDarkMedium = "#352E49 !important";
const purpleDivDark = "#7D08F2 !important";
const purpleIcon = "#5D1DCD !important";
const purpleLight = "#7A69B9 !important";
const purpleLightStation = "#F0ECFE !important";
const purpleLightest = "#beb8d2 !important";
const purpleMedium = "#6236F2 !important";
const bgSubmenuHover = "#413A58 !important";
const purpleMediumSub = "#4C17A1 !important";
const purplePrimary = "#551DCC";
const purpleDetail = "#5E33FF !important";
const purpleSecondary = "#310AA2 !important";
const purpleTextButton = "#6161E3 !important";
const purpleBackButton = "#E2E2FB !important";
const purpleSubTitle = "#5F45BF !important";
const purpleAvatarName = "#5A3DB8 !important";
const white = "#FFFFFF !important";
const yellow = "#EBB806 !important";
const pinkMedium = "#FF3A51 !important";
const redBrush = "#FF0000";
const purpleStatus = "#4C17A1 !important";
const purpleLightMark = "#E3DDFA !important";
const lilacButtonCam1 = "#AA84FA !important";
const grayButtonCam2 = "#464252 !important";
const purpleMenuActive = "#413A58 !important";
const greenLight = "#D1E9CF !important";
const whiteLight = "#F5F5F5 !important";
const grayClose = "#545454 !important";
const purpleNewDivider = "#A24EF6 !important";
const redLight = "#f6ecf0 !important";
const redMedium = "#ea7ab0 !important";

const colors = {
  black,
  redLight,
  redMedium,
  blackLight,
  blackMedium,
  blue,
  blueMedium,
  blueDark,
  blueDarkest,
  gray,
  grayLight,
  grayLightest,
  green,
  greenDark,
  greenMedium,
  lilac,
  lilacDarkMedium,
  lilacLight,
  pinkMedium,
  lilacLightest,
  lilacMedium,
  lilacMediumLight,
  lilacPerformance,
  lilacPerformanceTitle,
  grayDLight,
  grayMedium,
  grayBarPerformance,
  pink,
  pinkDark,
  pinkLight,
  purple,
  purpleDark,
  purpleDarkest,
  purpleDarkMedium,
  purpleDivDark,
  purpleIcon,
  purpleLight,
  purpleLightest,
  purpleMedium,
  bgSubmenuHover,
  purpleMenuActive,
  purpleMediumSub,
  purplePrimary,
  purpleSecondary,
  purpleTextButton,
  purpleBackButton,
  purpleSubTitle,
  purpleAvatarName,
  purpleLightStation,
  purpleStatus,
  purpleDetail,
  purpleLightMark,
  white,
  yellow,
  grayTitlePoints,
  blackDark,
  greenMediumLight,
  grayFeedbackBg,
  redBrush,
  grayMediumLight,
  purpleButtonCam,
  lilacButtonCam1,
  grayButtonCam2,
  grayStatusDataIA,
  greenDarkButton,
  greenLight,
  whiteLight,
  grayClose,
  purpleNewDivider,
};

export default colors;
