import api from "../config/api";
import IUsersData from "../types/Users";
import IUserList from "../types/Users";

const store = (data: any) => {
  return api.post("/users", data);
};

const getAll = (page: number, paginate: number, query?: string) => {
  let endpoint = `/users?page=${page}&paginate=${paginate}`;
  if (query) {
    endpoint += `&filter=${query}`;
  }
  return api.get<IUserList>(endpoint);
};

const get = (id: any) => {
  return api.get<IUsersData>("/users/" + id);
};

const update = (id: any, data: any) => {
  return api.put("/users/" + id, data);
};

const remove = (id: any) => {
  return api.delete("/users/" + id);
};

const getStudents = () => {
  return api.get<IUsersData>("/users/students");
};

const getAppraisers = (paginate: number) => {
  return api.get<IUsersData>("/users/appraisers?paginate=" + paginate);
};

const resetPassword = (id: any, data: any) => {
  return api.post<IUsersData>(`/users/${id}/resetpassword`, data);
};

const importUser = (data: any) => {
  return api.post(`/users/import`, data);
};

const UsersService = {
  store,
  getAll,
  get,
  update,
  remove,
  getStudents,
  getAppraisers,
  resetPassword,
  importUser,
};

export default UsersService;
