import React, { useState, useEffect } from "react";
import { Button, Box, Typography, LinearProgress, Grid } from "@mui/material";
import { useStyles } from "./styles/index.styles";
import EventsService from "../../../services/EventsService";
import { EventConclusionModal } from "../../../common/components/modal/EventConclude/EventConclusionModal";

import { toast } from "react-toastify";

import moment from "moment";
import "moment/locale/pt-br";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { useTranslation } from "react-i18next";

interface ConcludedEventProps {
  event: any;
  getCurrentEvent: any;
}

const ConcludeEvent: React.FC<ConcludedEventProps> = ({
  event,
  getCurrentEvent,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [eventConcluded, setEventConcluded] = useState(false);
  const [correctionsCompleted, setCorrectionsCompleted] = useState(0);

  useEffect(() => {}, [eventConcluded, event]);

  console.log(event);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleEventConcluded = async () => {
    try {
      await EventsService.concludeEvent(event?.id);
      getCurrentEvent(event?.id);
      toast.success(t("toast_conclude_event_description_success"));
    } catch (error) {
      toast.error(t("toast_conclude_event_description_error"));
    }
  };

  return (
    <Box>
      {event?.status == "in_correction" && (
        <Typography
          variant="h6"
          sx={{ marginBottom: "40px", textAlign: "left" }}
        >
          {t("first_description_dialog_in_correction")}
          {t("second_description_dialog_in_correction")}
        </Typography>
      )}
      {event?.status == "concluded" && (
        <Typography
          variant="h6"
          sx={{ marginBottom: "40px", textAlign: "left" }}
        >
          {t("first_description_dialog_concluded")}
          {moment(event.concluded_date).format("DD/MM/YYYY")}
          {t("second_description_dialog_concluded")}
        </Typography>
      )}

      <Box
        className={
          event?.status == "concluded"
            ? classes.concludedBox
            : classes.boxConclude
        }
      >
        <Box
          className={classes.boxConcludeProgress}
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            flexWrap: "wrap",
            gap: { xs: 1, lg: 2 },
          }}
        >
          {event?.status == "concluded" && (
            <CheckCircleIcon
              sx={{
                color: "#2E7D32",
                fontSize: "28px",
                alignItems: "center",
              }}
            />
          )}
          <Typography
            variant="h5"
            sx={{
              textAlign: "left",
              fontSize: { xs: "16px", lg: "20px" },
            }}
          >
            <strong>
              {event?.status == "concluded"
                ? t("concluded")
                : t("title_conclusion_of_the_event")}
            </strong>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: { xs: 0, sm: "auto" },
              flexWrap: "wrap",
              justifyContent: { xs: "flex-start", sm: "flex-end" },
              width: { xs: "100%", sm: "auto" },
              mt: { xs: 1, sm: 0 },
            }}
          >
            <LinearProgress
              className={classes.progressBar}
              sx={{
                width: { xs: "120px", lg: "180px" },
                height: 8,
                borderRadius: 5,
                mr: 2,
              }}
              variant="determinate"
              value={correctionsCompleted}
            />
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "14px", lg: "16px" },
                textAlign: "left",
                whiteSpace: "nowrap",
              }}
            >
              {correctionsCompleted}% das correções concluídas.
            </Typography>
          </Box>
        </Box>

        {event?.status == "concluded" && (
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: { xs: "14px", lg: "16px" },
                textAlign: "left",
              }}
            >
              {t("first_description_dialog_events_concluded")}
              <strong>{t("strong_dialog_events_concluded")}</strong>
              {t("second_description_dialog_events_concluded")}
              &quot;{t("quotation_marks_description_dialog_events_concluded")}
              &quot;.
            </Typography>
          </Box>
        )}

        {event?.status == "in_correction" && (
          <Grid
            container
            spacing={3}
            className={classes.contentContainer}
            alignItems="center"
          >
            <Grid item xs={12} md={6}>
              <Typography
                variant="body2"
                className={classes.description}
                sx={{
                  fontSize: { xs: "14px", lg: "16px" },
                  textAlign: "left",
                  mb: { xs: 2, md: 0 },
                }}
              >
                {t("first_description_dialog_events_in_correction")}
                {t("second_description_dialog_events_in_correction")}
                {t("third_description_dialog_events_in_correction")}
                <strong>
                  &quot;{t("strong_description_dialog_events_in_correction")}
                  &quot;
                </strong>
                .
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                className={classes.boxConcludeButton}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", md: "flex-end" },
                }}
              >
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleOpenModal}
                  sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                >
                  {t("btn_complete_event")}
                </Button>
              </Box>
            </Grid>
          </Grid>
        )}

        {modalOpen && (
          <EventConclusionModal
            eventId={event?.id}
            onClose={handleCloseModal}
            onConclude={handleEventConcluded}
          />
        )}
      </Box>
    </Box>
  );
};

export default ConcludeEvent;
