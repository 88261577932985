import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import TableHead from "@mui/material/TableHead";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import UsersService from "../../services/UsersService";
import IUserList from "../../types/Users";
import secureLocalStorage from "react-secure-storage";
import { useDefaultStyles } from "../../common/styles/default";
import AvatarHiplade from "../../common/components/avatarhiplade";
import { Card, Divider, Grid } from "@mui/material";
import colors from "../../common/styles/colors";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/banner_title_page_list_users.png";
import AddIcon from "@mui/icons-material/Add";
import Papa from "papaparse";

import SearchBar from "../../common/components/search/SearchBar"; // Importação do componente de pesquisa

interface TablePaginationActionsProps {
  count: any;
  page: any;
  rowsPerPage: any;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function List() {
  const navigate = useNavigate();
  const theme = useTheme(); // Usando o hook useTheme para acessar os breakpoints
  const styles = useDefaultStyles();

  const { t } = useTranslation();

  const paginate = 1;
  const firstpage = 1;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [users, setUsers] = useState<IUserList>();
  const [userRemove, setUserRemove] = useState("");
  const [nameFile, setNameFile] = useState("cvs_file");

  const [open, setOpen] = React.useState(false);

  const localPermissions = secureLocalStorage
    .getItem("permissions")
    ?.toString();
  const permissions = localPermissions?.split(",");

  const handleAdd = () => {
    navigate("/users/form");
  };

  const handleClickOpen = (id: any) => {
    setUserRemove(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    const npage = newPage + 1;

    setPage(newPage);
    loadUsers(npage, paginate);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {};

  const loadUsers = async (page: number, paginate: number, query?: string) => {
    await UsersService.getAll(page, paginate, query)
      .then((response: any) => {
        if (response) {
          setUsers(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const editUser = (id: number) => {
    navigate("/users/form/" + id);
  };

  const [dataCSV, setDataCSV] = useState([]);
  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const uploadFile = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      delimiter: ";",
      transformHeader: (header) => {
        if (header.toLowerCase() === "nome") return "Nome";
        if (header.toLowerCase() === "email") return "E-mail";
        return header;
      },
      complete: (result) => {
        if (result.data && result.data.length > 0) {
          // Processar dados antes de armazenar
          const processedData = result.data.map((row) => {
            // Copiar todos os dados originais
            const processedRow = { ...row };

            // Garantir que o campo Nome exista
            if (!("Nome" in processedRow)) {
              processedRow["Nome"] = "";
            }

            // Garantir que o campo E-mail exista
            if (!("E-mail" in processedRow)) {
              processedRow["E-mail"] = "";
            }

            // Adicionar campos de validação
            const nome = String(processedRow["Nome"] || "").trim();
            const email = String(processedRow["E-mail"] || "").trim();

            processedRow["_nome_vazio"] = !nome;
            processedRow["_email_vazio"] = !email;

            // Validar formato do e-mail se estiver preenchido
            processedRow["_email_invalido"] =
              email !== "" && !isValidEmail(email);

            // Adicionar Perfil Discente
            processedRow["Perfil"] = "Discente";

            // Status geral - válido apenas se nome preenchido E email preenchido e válido
            const linhaValida = nome && email && isValidEmail(email);
            processedRow["_status"] = linhaValida ? "valido" : "invalido";

            return processedRow;
          });

          // Armazenar os dados CSV processados no localStorage
          secureLocalStorage.setItem("csvData", JSON.stringify(processedData));
          secureLocalStorage.setItem("csvFileName", file.name);

          // Armazenar explicitamente os cabeçalhos visíveis no localStorage
          const visibleHeaders = ["Nome", "E-mail", "Perfil", "Status"];
          secureLocalStorage.setItem(
            "csvHeaders",
            JSON.stringify(visibleHeaders)
          );

          // Redirecionar para a página de análise do CSV
          navigate("/users/csv-preview");
        }
      },
    });
  };

  const removeUser = () => {
    UsersService.remove(userRemove)
      .then((response: any) => {
        setUserRemove("");
        loadUsers(firstpage, paginate);
        setOpen(false);
      })
      .catch((e: Error) => {});
  };

  const handleSearch = (query: string) => {
    loadUsers(firstpage, paginate, query);
  };

  useEffect(() => {
    loadUsers(firstpage, paginate);
  }, []);

  return (
    <Box>
      <Banner
        type="medium"
        image={banner}
        title={t("banner_title_page_list_users")}
        subtitle={t("banner_subtitle_page_list_users")}
      />
      <Box sx={{ backgroundColor: colors.purpleLightStation }}>
        <Box
          sx={{
            width: "100%",
            position: "relative",
            paddingLeft: "140px",
            paddingRight: "220px",
            paddingTop: "60px",
            paddingBottom: "60px",

            [theme.breakpoints.down("sm")]: {
              //border: '1px solid black',
              position: "relative",
              ml: "25px",
              width: "94%",
              paddingLeft: "5px",
              paddingRight: "30px",
            },

            [theme.breakpoints.between("sm", "md")]: {
              //border: '1px solid black',
              position: "relative",
              ml: "25px",
              width: "94%",
              paddingLeft: "53px",
              paddingRight: "53px",
            },

            [theme.breakpoints.between("md", "lg")]: {
              //border: '1px solid black',
              position: "relative",
              ml: "25px",
              width: "92%",
              paddingLeft: "34px",
              paddingRight: "30px",
            },

            [theme.breakpoints.between("lg", "xl")]: {
              //border: '1px solid black',
              position: "relative",
              ml: "25px",
              width: "96%",
              paddingLeft: "65px",
              paddingRight: "75px",
            },
          }}
        >
          {permissions?.includes("create_user") && (
            <Box
              sx={{
                mb: 4,
                padding: 4,
                border: `1px solid ${colors.grayDLight}`,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={10}>
                  {t("text_btn_add")}
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" onClick={handleAdd}>
                    {t("btn_add")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {permissions?.includes("create_user") && (
            <Card>
              <Box sx={{ padding: 2 }}>
                <Typography variant="h5">{t("title_register_cvs")}</Typography>
                <Divider />
                <Box sx={{ mt: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={10}>
                      <Typography>{t("text_register_csv")}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box>
                        <input
                          id={nameFile}
                          name={nameFile}
                          style={{ display: "none" }}
                          onChange={(
                            event: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            if (event.target.files != null) {
                              uploadFile(event);
                            }
                          }}
                          accept=".csv"
                          type="file"
                        />
                        <label htmlFor={nameFile}>
                          <Typography
                            sx={{
                              borderRadius: "4px",
                              backgroundColor: colors.greenMediumLight,
                              boxShadow:
                                "0px 4px 8px 0px rgba(40, 102, 105, 0.20)",
                              cursor: "pointer",
                              color: colors.white,
                              padding: "10px",
                              textAlign: "center",
                              width: "185px !important",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {t("btn_csv")}
                            <AddIcon
                              sx={{
                                fontSize: "18px",
                                ml: 1,
                                fontWeight: "bold",
                              }}
                            />
                          </Typography>
                        </label>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          )}

          {/* Adicione um espaço após o Card de importação CSV */}
          <Box sx={{ mt: 3 }}></Box>
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          position: "relative",
          paddingLeft: "60px",
          paddingRight: "220px",
          paddingBottom: "100px",

          [theme.breakpoints.down("sm")]: {
            //border: '1px solid black',
            position: "relative",
            ml: "25px",
            width: "94%",
            paddingLeft: "5px",
            paddingRight: "30px",
          },

          [theme.breakpoints.between("sm", "md")]: {
            //border: '1px solid black',
            position: "relative",
            ml: "25px",
            width: "94%",
            paddingLeft: "53px",
            paddingRight: "53px",
          },

          [theme.breakpoints.between("md", "lg")]: {
            //border: '1px solid black',
            position: "relative",
            ml: "25px",
            width: "92%",
            paddingLeft: "34px",
            paddingRight: "30px",
          },

          [theme.breakpoints.between("lg", "xl")]: {
            //border: '1px solid black',
            position: "relative",
            ml: "25px",
            width: "96%",
            paddingLeft: "65px",
            paddingRight: "75px",
          },
        }}
        className={styles.container}
      >
        <SearchBar onSearch={handleSearch} />

        <TableContainer component={Paper} sx={{ mt: 5 }}>
          <Table sx={{ minWidth: 600 }} aria-label="custom pagination table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_name")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_email")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_document1")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_document2")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_registration")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_profile")}
                </TableCell>
                <TableCell sx={{ fontWeight: "bold", fontSize: 20 }}>
                  {t("table_label_button")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users?.data.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    <Grid container spacing={0}>
                      <Grid item sm={2}>
                        <AvatarHiplade
                          nameUser={row.name}
                          size="mini"
                          id={row.id}
                          source={row.avatar[0]?.static_url}
                        />
                      </Grid>
                      <Grid item sm={10}>
                        {row.name}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {row.email ? (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        {row.email}
                        {!isValidEmail(row.email) && (
                          <Tooltip
                            title="Formato de e-mail inválido"
                            placement="top"
                          >
                            <InfoOutlinedIcon
                              sx={{
                                ml: 1,
                                color: "#C0135A",
                                fontSize: "1rem",
                              }}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography sx={{ color: "#FE3A8A", mr: 1 }}>
                          Não informado
                        </Typography>
                        <Tooltip
                          title="O campo E-mail não foi informado. Este campo é recomendado."
                          arrow
                        >
                          <InfoOutlinedIcon
                            fontSize="small"
                            sx={{ color: "#C0135A" }}
                          />
                        </Tooltip>
                      </Box>
                    )}
                  </TableCell>
                  <TableCell>{row.cpf}</TableCell>
                  <TableCell>{row.rg}</TableCell>
                  <TableCell>{row.ra}</TableCell>
                  <TableCell>{t(row.roles[0]?.name)}</TableCell>

                  <TableCell>
                    {permissions?.includes("update_user") && (
                      <EditIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => editUser(row.id)}
                      />
                    )}
                    {permissions?.includes("delete_user") && (
                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleClickOpen(row.id)}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {users && users?.total > rowsPerPage && (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[]}
                    colSpan={7}
                    count={users?.total}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={t("table_label_number_per_page")}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </TableContainer>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {t("title_dialog_delete_user")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t("description_dialog_delete_user")}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{t("btn_dialog_no")}</Button>
            <Button onClick={removeUser} autoFocus>
              {t("btn_dialog_yes")}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
