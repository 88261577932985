import * as React from "react";

import { useStyles } from "./styles/index.styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import { Button, Divider, Grid, Typography } from "@mui/material";
import Box from "@material-ui/core/Box";

type props = {
  type?: "big" | "student" | "medium" | "small" | "icon";
  title?: any;
  subtitle?: any;
  subtitle_second?: any;
  image?: any;
  icon?: any;
  rounded?: any;
  divider?: any;
  colorDivider?: any;
  shadow?: any;
  button?: any;
  avatar?: any;
};

export default function Banner({
  type,
  title,
  subtitle,
  subtitle_second,
  image,
  icon,
  rounded,
  divider,
  colorDivider,
  shadow,
  button,
  avatar,
}: props) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <Box
      className={
        type == "big"
          ? classes.bigBanner
          : type == "medium"
          ? classes.mediumBanner
          : type == "student"
          ? classes.studentBanner
          : type == "icon"
          ? classes.iconBanner
          : classes.smallBanner
      }
      style={{
        backgroundImage:
          type == "big" || type == "medium" ? `url(${image})` : "",
        boxShadow: shadow == 1 ? "0px 4px 10px rgba(91, 28, 203, 0.5)" : "",
      }}
      sx={{ borderRadius: rounded ? rounded : "" }}
    >
      <Grid container spacing={2}>
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={11}
          className={
            type == "big"
              ? classes.gridBigBanner
              : type == "medium"
              ? classes.gridMediumBanner
              : type == "icon"
              ? classes.gridIconBanner
              : classes.gridSmallBanner
          }
        >
          {type != "student" && type != "icon" && (
            <Box className={type == "small" ? classes.smallContainer : ""}>
              {type == "small" && <Box mt={2}> {icon} </Box>}
              <Typography
                className={
                  type == "big"
                    ? classes.titleBigBanner
                    : type == "medium"
                    ? classes.titleMediumBanner
                    : classes.titleSmallBanner
                }
                sx={{
                  fontWeight: 700,
                  fontSize: {
                    xs:
                      type == "big"
                        ? "1.75rem"
                        : type == "medium"
                        ? "1.5rem"
                        : "1.25rem",
                    sm:
                      type == "big"
                        ? "2rem"
                        : type == "medium"
                        ? "1.75rem"
                        : "1.4rem",
                    md:
                      type == "big"
                        ? "2.5rem"
                        : type == "medium"
                        ? "2rem"
                        : "1.5rem",
                    lg:
                      type == "big"
                        ? "3rem"
                        : type == "medium"
                        ? "2.5rem"
                        : "1.75rem",
                  },
                }}
              >
                {title}
              </Typography>
              {divider == "1" && (
                <Divider
                  className={
                    colorDivider == "light"
                      ? classes.dividerLight
                      : colorDivider == "new"
                      ? classes.dividerNew
                      : classes.divider
                  }
                />
              )}
              {type != "small" && (
                <Typography
                  className={classes.subtitleBanner}
                  sx={{
                    fontWeight: 500,
                    fontSize: {
                      xs: "0.875rem",
                      sm: "1rem",
                      md: "1.125rem",
                      lg: "1.25rem",
                    },
                  }}
                >
                  {subtitle}
                </Typography>
              )}
            </Box>
          )}
          {type == "small" && button}
          {type == "student" && (
            <Box className={classes.studentContainer}>
              <Box className={classes.subStudentContainer}>
                <Box className={classes.studentAvatar}> {avatar} </Box>
                <Box>
                  <Typography
                    className={classes.subtitleStudentBanner}
                    sx={{
                      fontWeight: 700,
                      fontSize: {
                        xs: "0.875rem",
                        sm: "1rem",
                        md: "1.125rem",
                      },
                    }}
                  >
                    {subtitle}
                  </Typography>
                  <Typography
                    className={classes.titleStudentBanner}
                    sx={{
                      fontWeight: 700,
                      fontSize: {
                        xs: "1.25rem",
                        sm: "1.5rem",
                        md: "1.75rem",
                        lg: "2rem",
                      },
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    className={classes.subSubtitleStudentBanner}
                    sx={{
                      fontSize: {
                        xs: "0.75rem",
                        sm: "0.875rem",
                        md: "1rem",
                      },
                    }}
                  >
                    {subtitle_second}
                  </Typography>
                </Box>
              </Box>
              {button}
            </Box>
          )}
          {type == "icon" && (
            <Box className={classes.iconContainer}>
              {icon && <Box className={classes.iconElement}>{icon}</Box>}
              <Typography
                className={classes.titleIconBanner}
                sx={{
                  fontWeight: 700,
                  fontSize: {
                    xs: "1.25rem",
                    sm: "1.5rem",
                    md: "1.75rem",
                  },
                }}
              >
                {title}
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
