import api from "../config/api";
import IAreaData from "../types/Area";

const fex = (data: any) => {
  return api.post("/sheai/fex", data);
};

const fexStatus = (debriefingId: any) => {
  return api.get("/sheai/statusfex/debriefing/" + debriefingId);
};

const background = (data: any) => {
  return api.post("/sheai/genhi/background", data);
};

const backgroundList = (userId: any) => {
  return api.get(`/sheai/genhi/mybackgrounds/user/${userId}`);
};

const SheAiService = {
  fex,
  fexStatus,
  background,
  backgroundList,
};

export default SheAiService;
