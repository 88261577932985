import React from "react";
import { Box, Button, Typography } from "@mui/material";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import AddIcon from "@mui/icons-material/Add";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

interface CsvActionsProps {
  onDownload: () => void;
  onDelete: () => void;
  onAddFile: () => void;
  onSendToApi: () => void;
  fileName: string;
  fileLoaded: boolean;
}

const CsvActions: React.FC<CsvActionsProps> = ({
  onDownload,
  onDelete,
  onAddFile,
  onSendToApi,
  fileName,
  fileLoaded,
}) => {
  return (
    <Box sx={{ my: 3, display: "flex", flexDirection: "column", gap: 2 }}>
      <Box
        sx={{
          backgroundColor: "#F0ECFE",
          borderRadius: "12px",
          p: 2,
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body1">
          {fileLoaded
            ? "Arquivo selecionado, caso necessário exclua o arquivo e recarregue novamente."
            : "Nenhum arquivo selecionado. Adicione um arquivo CSV para prosseguir."}
        </Typography>

        {fileLoaded ? (
          <Button
            variant="contained"
            endIcon={<HighlightOffOutlinedIcon sx={{ color: "#6161E3" }} />}
            onClick={onDelete}
            sx={{
              textTransform: "none",
              ml: 2,
              flexShrink: 0,
              backgroundColor: "#E2E2FB",
              color: "#6161E3",
              "&:hover": {
                backgroundColor: "#d4d4f7",
              },
              boxShadow: "none",
              "& .MuiButton-label": {
                color: "#6161E3",
              },
            }}
          >
            <Typography sx={{ color: "#6161E3", fontWeight: "regular" }}>
              {fileName}
            </Typography>
          </Button>
        ) : (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={onAddFile}
            sx={{
              textTransform: "none",
              ml: 2,
              flexShrink: 0,
              backgroundColor: "#6C9C1E",
              "&:hover": {
                backgroundColor: "#5A8219",
              },
            }}
          >
            Adicionar arquivo
          </Button>
        )}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, gap: 2 }}>
        {fileLoaded && (
          <>
            {/* <Button
              variant="contained"
              color="primary"
              onClick={onSendToApi}
              startIcon={<CloudUploadIcon />}
              sx={{
                backgroundColor: "#6C9C1E",
                "&:hover": {
                  backgroundColor: "#5A8219",
                },
              }}
            >
              Importar Usuários
            </Button> */}
            {/* <Button variant="contained" color="primary" onClick={onDownload}>
              Continuar
            </Button> */}
          </>
        )}
      </Box>
    </Box>
  );
};

export default CsvActions;
