import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import CheckListService from "../../services/CheckListService";
import { useDefaultStyles } from "../../common/styles/default";
import { useStyles } from "../styles";

import Banner from "../../common/components/banner";
import banner from "../../assets/images/banners/event_checklist_banner.png";

import FormGeneralData from "./FormGeneralData";
import FormGeneralSkills from "./FormGeneralSkills";

import ThemesService from "../../services/ThemesService";
import IThemeList from "../../types/Themes";
import ICompetenceList from "../../types/Competence";
import CompetenceService from "../../services/CompetenceService";
import { Divider, SelectChangeEvent } from "@mui/material";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import colors from "../../common/styles/colors";
import AvatarHiplade from "../../common/components/avatarhiplade";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";

export default function Form() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const styles = useDefaultStyles();
  const classes = useStyles();

  const { id } = useParams();

  const [name, setName] = useState("");
  const [valueDid, setValueDid] = useState("");
  const [valueHalfDid, setValueHalfDid] = useState("");
  const [valueDidWrong, setValueDidWrong] = useState("");
  const [valueDidNt, setValueDidNt] = useState("");
  const [idChecklist, setIdChecklist] = useState(id);
  const [themes, setThemes] = useState<IThemeList[]>([]);
  const [theme_id, setThemeId] = useState("");
  const [competences, setCompetences] = useState<IThemeList[]>([]);
  const [competence_id, setCompetenceId] = useState("");
  const [status, setStatus] = useState("");
  const [author, setAuthor] = useState<any>({});
  const [created_at, setCreatedAt] = useState("");
  const [like, setLike] = useState("0");

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const backChecklistList = () => {
    navigate("/checklist/list");
  };

  const handleThemeChange = (event: SelectChangeEvent) => {
    setThemeId(event.target.value);
  };

  const handleCompetenceChange = (event: SelectChangeEvent) => {
    setCompetenceId(event.target.value);
  };

  const handleSubmit = () => {
    if (name == "") {
      toast(t("toast_msg_formchecklist_nome"), {
        type: "error",
      });
      return;
    }

    if (theme_id == "") {
      toast(t("toast_msg_formchecklist_theme"), {
        type: "error",
      });
      return;
    }

    if (
      valueDid.toString() == "" ||
      parseInt(valueDid) < 0 ||
      parseInt(valueDid) > 100
    ) {
      toast(t("toast_msg_formchecklist_done"), {
        type: "error",
      });
      return;
    }

    if (
      valueHalfDid.toString() == "" ||
      parseInt(valueHalfDid) < 0 ||
      parseInt(valueHalfDid) > 100
    ) {
      toast(t("toast_msg_formchecklist_partially_done"), {
        type: "error",
      });
      return;
    }

    if (
      valueDidWrong.toString() == "" ||
      parseInt(valueDidWrong) < 0 ||
      parseInt(valueDidWrong) > 100
    ) {
      toast(t("toast_msg_formchecklist_done_wrong"), {
        type: "error",
      });
      return;
    }

    if (
      valueDidNt.toString() == "" ||
      parseInt(valueDidNt) < 0 ||
      parseInt(valueDidNt) > 100
    ) {
      console.log(valueDidNt);
      toast(t("toast_msg_formchecklist_not_done"), {
        type: "error",
      });
      return;
    }

    const dataChecklist = {
      //theme: theme,
      name: name,
      theme_id: theme_id,
      competence_id: competence_id,
      done: valueDid,
      partially_done: valueHalfDid,
      done_wrong: valueDidWrong,
      not_done: valueDidNt,
    };

    if (!idChecklist) {
      CheckListService.store(dataChecklist)
        .then((response: any) => {
          setIdChecklist(response.data.id);
          toast(response?.data?.Msg, {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    } else {
      CheckListService.update(idChecklist, dataChecklist)
        .then((response: any) => {
          toast(response?.data?.Msg, {
            type: "success",
          });
        })
        .catch((e: any) => {
          toast(e?.response?.data?.Error, {
            type: "error",
          });
        });
    }
  };

  const loadChecklist = async (id: any) => {
    CheckListService.get(id)
      .then((response: any) => {
        setIdChecklist(response.data.id);
        setCompetenceId(response.data.competence_id);
        setName(response.data.name);
        setThemeId(response.data.theme_id);
        setValueDid(response.data.done);
        setValueDidWrong(response.data.done_wrong);
        setValueHalfDid(response.data.partially_done);
        setValueDidNt(response.data.not_done);
        setStatus(response.data.status);
        setAuthor(response.data.author);
        setCreatedAt(response.data.created_at);
        setLike(response.data.like);
      })
      .catch((e: any) => {
        toast(e?.response?.data?.Error, {
          type: "error",
        });
      });
  };

  const loadThemes = async (page: number, paginate: number) => {
    await ThemesService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setThemes(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const loadCompetences = async (page: number, paginate: number) => {
    await CompetenceService.getAll(page, paginate)
      .then((response: any) => {
        if (response) {
          setCompetences(response.data);
        }
      })
      .catch((e: Error) => {});
  };

  const publishChecklist = async (id: any) => {
    await CheckListService.publish(id)
      .then((response: any) => {
        toast(response?.data?.Msg, {
          type: "success",
        });
        loadChecklist(idChecklist);
      })
      .catch((e: Error) => {});
  };

  const voteChecklist = async (id: any, vote: any) => {
    await CheckListService.vote(id, vote)
      .then((response: any) => {
        toast(response?.data?.Msg, {
          type: "success",
        });
        loadChecklist(idChecklist);
      })
      .catch((e: Error) => {});
  };

  const handleSubmitClose = () => {
    handleSubmit();
    backChecklistList();
  };

  useEffect(() => {
    loadThemes(1, 0);
    loadCompetences(1, 0);
    if (idChecklist) {
      loadChecklist(idChecklist);
    }
  }, []);

  return (
    <Box>
      <Banner
        type="small"
        image={banner}
        title={
          <Box sx={{ display: "flex", alignItems: "center", gap: 5 }}>
            <PlaylistAddOutlinedIcon sx={{ width: "3em", height: "3em" }} />
            <Typography variant="h3" sx={{ fontWeight: "bold" }}>
              {!id
                ? t("title_page_form_register_checklist")
                : t("title_page_form_register_checklist_edit")}
            </Typography>
          </Box>
        }
      />
      <Box>
        <Box className={classes.containerForm}>
          <Box className={classes.containerSubColor}>
            <Box className={classes.subContainer}>
              {idChecklist && (
                <Box
                  sx={{
                    mb: 4,
                    border: `1px solid ${
                      status == "published"
                        ? colors.greenMedium
                        : colors.redMedium
                    }`,
                    padding: 3,
                    backgroundColor:
                      status == "published"
                        ? colors.greenLight
                        : colors.redLight,
                    borderRadius: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                      {name}
                    </Typography>
                    <Typography>
                      <b> Data: </b>
                      {moment(created_at).format("DD/MM/YYYY ")}
                    </Typography>
                  </Box>
                  <Divider sx={{ mt: 3, mb: 3 }} />
                  {status != "published" && (
                    <Typography sx={{ fontWeight: "bold" }}>
                      <b>Instruções: </b>Não se esqueça de validar as
                      informações antes de publicar e avaliar com um “like” se o
                      item gerado foi satisfatório.
                    </Typography>
                  )}
                  {status != "published" && <Divider sx={{ mt: 3, mb: 3 }} />}
                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    {author && (
                      <Typography sx={{ fontWeight: "bold" }}>
                        Autor:{" "}
                      </Typography>
                    )}
                    {author && (
                      <AvatarHiplade
                        nameUser={author?.name}
                        size="mini"
                        id={author?.id}
                        source={
                          author?.avatar ? author?.avatar[0]?.static_url : ""
                        }
                      />
                    )}
                    {author?.name}{" "}
                    {author?.name == "AI GenHi"
                      ? " - (Com uso da ferramenta GenHi)"
                      : " "}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      mt: 3,
                    }}
                  >
                    <Typography>
                      <b>Status: </b> {t(status)}
                    </Typography>
                    {status != "published" && (
                      <InfoIcon
                        sx={{ width: "2em", color: colors.redMedium }}
                      />
                    )}
                    {status == "published" && (
                      <CheckCircleIcon
                        sx={{ width: "2em", color: colors.greenMedium }}
                      />
                    )}
                  </Box>
                  {status == "unpublished" && (
                    <Box sx={{ mt: 2, textAlign: "right" }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => publishChecklist(idChecklist)}
                      >
                        <b>Publicar</b>
                      </Button>
                    </Box>
                  )}
                  {status == "published" && (
                    <Box
                      sx={{
                        mt: 2,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <ThumbDownIcon
                        sx={{
                          width: "2em",
                          color: like == "0" ? colors.black : colors.gray,
                          cursor: "pointer",
                        }}
                        onClick={() => voteChecklist(idChecklist, 0)}
                      />
                      |
                      <ThumbUpIcon
                        sx={{
                          width: "2em",
                          color:
                            like && like == "1" ? colors.black : colors.gray,
                          cursor: "pointer",
                        }}
                        onClick={() => voteChecklist(idChecklist, 1)}
                      />
                    </Box>
                  )}
                </Box>
              )}
              <FormGeneralData
                name={name}
                setName={setName}
                setThemeId={setThemeId}
                theme_id={theme_id}
                handleThemeChange={handleThemeChange}
                themes={themes}
                openModal={openModal}
                setOpenModal={setOpenModal}
                valueDid={valueDid}
                valueHalfDid={valueHalfDid}
                valueDidWrong={valueDidWrong}
                valueDidNt={valueDidNt}
                setValueDid={setValueDid}
                setValueHalfDid={setValueHalfDid}
                setValueDidWrong={setValueDidWrong}
                setValueDidNt={setValueDidNt}
                idChecklist={idChecklist}
                loadChecklist={loadChecklist}
                loadThemes={loadThemes}
                competence_id={competence_id}
                handleCompetenceChange={handleCompetenceChange}
                loadCompetences={loadCompetences}
                competences={competences}
              />
              <Box sx={{ mb: 4, textAlign: "right" }}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  onClick={handleSubmit}
                >
                  {" "}
                  {t("btn_save")}
                </Button>
              </Box>
            </Box>
          </Box>
          {idChecklist && (
            <Box className={classes.subContainerSkill}>
              <FormGeneralSkills idChecklist={idChecklist} idTheme={theme_id} />
            </Box>
          )}
        </Box>
      </Box>
      {idChecklist && (
        <Box className={classes.containerSubColor}>
          <Box className={classes.subContainerButtons}>
            <Box sx={{ mb: 4 }}></Box>
            <Box className={classes.buttonsFooter}>
              <Button
                variant="contained"
                color="secondary"
                sx={{ mr: 3 }}
                size="large"
                onClick={backChecklistList}
              >
                {" "}
                {t("btn_return")}
              </Button>
              <Button
                variant="contained"
                size="large"
                onClick={handleSubmitClose}
              >
                {" "}
                {t("btn_save_and_conclude")}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
