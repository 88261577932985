import React, { useCallback, useRef, useEffect } from "react";
import { Box } from "@mui/system";
import Stack from "@mui/material/Stack";
import EventsService from "../../services/EventsService";
import { useStyles } from "../../events/styles/index.styles";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { AvatarGroup, Button, Divider, Grid } from "@mui/material";
import IStationData from "../../types/Station";
import GetObjectAttribute from "../../common/components/services/GetObjectAttribute";
import StationStudentsList from "./StationStudentsList";
import "moment/locale/pt-br";
import AvatarHiplade from "../../common/components/avatarhiplade";
import SwitchAccountIcon from "@mui/icons-material/SwitchAccount";
import Switch from "@mui/material/Switch";
import colors from "../../common/styles/colors";
import { useObserver } from "../hooks/useObserver";
import ContactPhoneOutlinedIcon from "@mui/icons-material/ContactPhoneOutlined";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../common/hooks/useModal";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

export default function EventStationsView({ event, status }: any) {
  if (!event) {
    return <Box />;
  }

  const styles = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const [stationList, setStationList] = React.useState<IStationData[]>([]);

  const navigate = useNavigate();

  const {
    getObserverStatusByStation,
    startObserver,
    stopObserver,
    observerStartState,
    observerStopState,
    observerStatusState,
  } = useObserver();

  const { handleOpenModal, dataModal, onResetModal } = useModal();

  const [checked, setChecked] = React.useState<any>("false");
  const [countToCallObserver, setCountToCallObserver] =
    React.useState<number>(0);

  function stringToBoolean(value: string) {
    if (typeof value === "string") {
      const lowerCaseValue = value?.toLowerCase();
      return lowerCaseValue === "true"
        ? true
        : lowerCaseValue === "false"
        ? false
        : Boolean(value);
    }
    return value;
  }

  const getStationsByEvent = async (eventId) => {
    await EventsService.getStationsByEvent(eventId)
      .then((response: any) => {
        let stationsByEvent = response.data;
        setStationList(stationsByEvent);
      })
      .catch((e: Error) => {});
  };

  const openMeeting = (idStation: any) => {
    navigate("/events/meeting/" + event?.id + "/" + idStation);
  };

  const handleObserver = useCallback(async () => {
    getObserverStatusByStation(event.stations[0].id);
  }, [observerStatusState?.success]);

  const handleChangeChecked = useCallback(
    async (eventTarget: React.ChangeEvent<HTMLInputElement>) => {
      setCountToCallObserver(0);
      const targetChecked = eventTarget.target.checked;
      setChecked(targetChecked);

      if (!targetChecked) {
        await stopObserver({
          station_id: event.stations[0].id,
        });
        handleObserver();
      } else {
        await startObserver({
          event_id: event.id,
          station_id: event.stations[0].id,
        });
        handleObserver();
      }
    },
    [observerStatusState.data?.active, checked]
  );

  useEffect(() => {
    getStationsByEvent(event.id);
    handleObserver();
    setCountToCallObserver(1);
  }, []);

  useEffect(() => {
    if (countToCallObserver === 1 && !observerStatusState.loading) {
      const state = observerStatusState.data?.active;
      setChecked(state);
    }
  }, [
    countToCallObserver,
    observerStatusState.success,
    observerStatusState.data?.active,
    checked,
    observerStatusState.loading,
  ]);

  return (
    <Box className={styles.container}>
      <Typography
        variant="h5"
        component="div"
        className={styles.titleCard}
        sx={{ fontWeight: 700 }}
      >
        {t("title_page_form_station_management")}
      </Typography>

      <Divider className={styles.dividerStation} />

      {status !== "concluded" && (
        <Stack
          className={styles.eventBoxListMonitoringRoomWithoutBG}
          sx={{ mt: 4 }}
          direction="row"
          alignItems="center"
          spacing={1}
          flexWrap={{ xs: "wrap", lg: "nowrap" }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              mr: 1,
              mb: { xs: 0, lg: 0 },
            }}
          >
            <SwitchAccountIcon sx={{ color: colors.purple }} fontSize="large" />
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              className={styles.titleStudent}
              sx={{
                textAlign: "left",
                fontSize: { xs: "14px", lg: "16px" },
              }}
            >
              <strong>{t("title_strong_event_stations_view")}</strong>
              {t("description_dialog_event_stations_view")}
            </Typography>
          </Box>
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              ml: { xs: "auto", lg: "auto" },
              mt: { xs: 1, sm: 0 },
              width: { xs: "100%", sm: "auto" },
              justifyContent: { xs: "flex-end", lg: "flex-end" },
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                mr: 1,
                fontSize: { xs: "14px", lg: "16px" },
              }}
            >
              {observerStartState.loading
                ? "carregando o status..."
                : observerStatusState.data?.status}
            </Typography>
            <Switch
              checked={stringToBoolean(checked)}
              onChange={handleChangeChecked}
              inputProps={{ "aria-label": "controlled" }}
              color="success"
            />
          </Stack>
        </Stack>
      )}

      {stationList.map((station, index) => (
        <Box key={station.id} sx={{ mt: 3 }}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <Typography
                className={styles.eventStationTitle}
                display="inline-block"
                sx={{ fontWeight: "bold" }}
              >
                {station.name + " / "}
              </Typography>

              <Typography
                className={styles.eventStationSubTitle}
                display="inline-block"
              >
                <GetObjectAttribute
                  foreignKey={station.scenario_id}
                  apiGet="scenarios"
                  attributeName="name"
                />
              </Typography>

              <Typography
                className={styles.eventStationSubTitle}
                display="inline-block"
              >
                / {station?.room_name}
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mb: 5 }}>
            <Divider />
          </Box>

          <Grid
            item
            xs={2}
            mb={5}
            className={styles.boxTitleStation}
            sx={{ justifyContent: "flex-start" }}
          >
            <Stack
              sx={{ flexDirection: "row", cursor: "pointer" }}
              onClick={() => handleOpenModal({ displayName: "AppraisersList" })}
            >
              <Stack sx={{ flexDirection: "row" }}>
                <AvatarHiplade
                  nameUser={station.appraiser_name}
                  id={station.appraiser_id}
                  size="small"
                  only
                />
                <Box sx={{ position: "relative" }}>
                  <Box sx={{ position: "absolute", left: "-30px" }}>
                    <AvatarHiplade
                      nameUser={station.appraiser_name}
                      id={station.appraiser_id}
                      size="small"
                      only
                    />
                  </Box>
                </Box>
              </Stack>
              <Stack sx={{ flexDirection: "row", mt: 1, ml: 3, gap: 1 }}>
                <Box className={styles.appraiserName}>
                  {station.appraiser_name},
                </Box>
                <Box className={styles.appraiserName}>
                  {station.appraiser_name}
                </Box>
                ...
                <b className={styles.appraiserName}>e outros</b>
              </Stack>
            </Stack>
          </Grid>

          {status !== "concluded" && (
            <Box>
              <Grid
                className={styles.eventBoxListMeeting}
                sx={{ mt: 4 }}
                container
                direction="row"
                spacing={2}
                alignItems="center"
                flexWrap={{ xs: "wrap", lg: "nowrap" }}
              >
                <Grid
                  item
                  xs="auto"
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    mb: { xs: 0, lg: 0 },
                    mr: { xs: 1, lg: 0 },
                  }}
                >
                  <ContactPhoneOutlinedIcon
                    sx={{ color: colors.purple }}
                    fontSize="large"
                  />
                </Grid>
                <Grid item xs>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "left",
                      fontSize: { xs: "14px", lg: "16px" },
                    }}
                    className={styles.titleMetting}
                  >
                    <strong>
                      {t("title_strong_event_stations_view_meeting")}
                    </strong>
                    {t("description_dialog_event_stations_view_meeting")}{" "}
                    <strong>
                      {t("second_title_strong_event_stations_view_meeting")}
                    </strong>
                    {t("second_description_dialog_event_stations_view_meeting")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm="auto"
                  sx={{
                    textAlign: { xs: "right", lg: "right" },
                    mt: { xs: 2, lg: 0 },
                    ml: { xs: "auto", lg: 0 },
                    display: "flex",
                    justifyContent: { xs: "flex-end", lg: "flex-end" },
                    width: { xs: "100%", sm: "auto" },
                  }}
                >
                  <Button
                    variant="outlined"
                    size="large"
                    sx={{
                      fontSize: { xs: "12px", lg: "14px" },
                      ml: { lg: 2 },
                    }}
                    className={styles.buttonMeeting}
                    onClick={() => openMeeting(station?.id)}
                  >
                    {t("btn_enter")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          )}

          {isMobile && (
            <>
              <Typography
                variant="body2"
                sx={{
                  mb: 2,
                  textAlign: "center",
                  fontWeight: 500,
                }}
              >
                Clique nos nomes abaixo para acessar as simulações, os icones a
                seguir correspondem o status de correção:
              </Typography>

              <Stack
                direction={isExtraSmall ? "column" : "row"}
                spacing={isExtraSmall ? 1 : 2}
                justifyContent="center"
                alignItems={isExtraSmall ? "flex-start" : "center"}
                sx={{
                  mb: 2,
                  width: "100%",
                  ...(isExtraSmall && { pl: 2 }),
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <CheckCircleIcon sx={{ color: "#41941A", mr: 0.5 }} />
                  <Typography variant="body2">Corrigido</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeFilledIcon sx={{ color: "#EBB806", mr: 0.5 }} />
                  <Typography variant="body2">Em correção</Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <ErrorOutlinedIcon sx={{ color: "#FE3A8A", mr: 0.5 }} />
                  <Typography variant="body2">Não corrigido</Typography>
                </Box>
              </Stack>
            </>
          )}

          <StationStudentsList
            studentsgroupId={event.studentsgroup_id}
            eventId={event.id}
            stationId={station.id}
            status={status}
            station={station}
          />
        </Box>
      ))}
    </Box>
  );
}
